import React, {useState, useCallback, useEffect} from 'react';
import Popup from '../components/Popups/Popup';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Stage from '../components/Stage';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';
import {Helmet} from 'react-helmet';



import './styles.scss';
import { on } from 'stream';

interface HomeProps {
  pageContext: {
    lang: string,
    heading: string,
    intro: string,
    fullPackage: {
        heading: string,
        text: string,
        buttonLabel?: string
    },
    onlinePackage: {
        heading: string,
        text: string,
        buttonLabel: string
    },
    bottomText: string,
    language: string,
    country: string
  },
  location: string

};


const Home: React.FC<HomeProps> = ({
  pageContext: {
    heading,
    intro,
    fullPackage,
    onlinePackage,
    bottomText,
    language,
    country
  } 
}) => {


  return (
    <>
        <Header language={language} country={country} urlParts={[country]}/>
        <Helmet>
          <title>The Home</title>
        </Helmet>
        <div className={`home-wrapper lang-${language} country-${country}`}>
            <div className="top">
                <div className="intro">
                    <h1>{parse(heading)}</h1>
                    {parse(intro)}
                </div>
                <div className='image-container'>
                  <img src={`/home/${country}-1.png`} alt="" />
                  <img src={`/home/${country}-2.png`} alt="" />
                  <img src={`/home/${country}-3.png`} alt="" />
                </div>
            </div>
            <div className="bottom">
                <div className="card">
                    <h2>{fullPackage.heading}</h2>
                    <p>{fullPackage.text}</p>
                    { country == "en" && 
                    <>
                      <a href='https://play.google.com/store/apps/details?id=com.thedigitalhome.thehome&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{height: "70px", width: "auto", marginLeft: "0px", paddingLeft: "0px"}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                      <a href="https://apps.apple.com/gb/app/the-home/id1588549992?itsct=apps_box_badge&amp;itscg=30200"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1633910400&h=07d5c5d0adb95849430337ff49e07eff" alt="Download on the App Store" style={{borderRadius: "10px", width: "auto", marginBottom: "10px", height: "50px"}} /></a>
                    </>
                    }
                    { country == "jp" && 
                    <>
                      <a href='https://play.google.com/store/apps/details?id=com.thedigitalhome.thehome&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{height: "70px", width: "auto", marginLeft: "0px", paddingLeft: "0px"}} alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png'/></a>
                      <a href="https://apps.apple.com/jp/app/the-home/id1588549992?itsct=apps_box_badge&amp;itscg=30200"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/ja-jp?size=250x83&amp;releaseDate=1634169600&h=c14ddf691fb251f83b17f4d75159987b" alt="Download on the App Store" style={{borderRadius: "10px", width: "auto", marginBottom: "10px", height: "50px"}} /></a>                      
                    </>
                    }

                </div>
                <div className="card">
                    <h2>{onlinePackage.heading}</h2>
                    <p>{onlinePackage.text}</p>
                    <a href={`/${country}/${language}/reception`} className="button">{onlinePackage.buttonLabel}</a>
                </div>
            </div>
        </div>
        <div className="footer">
            <p>{parse(bottomText)}</p>
        </div>
    </>
  );
};

export default Home;